<template>
  <b-modal
    id="create-transection"
    v-model="$show"
    centered
    scrollable
    size="xl"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <b-row class="m-0">
      <b-col cols="12" :class="transaction ? `p-0` : ``">
        <filter-master
          v-if="!isReadOnly"
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{}"
        >
        </filter-master>

        <b-row v-if="transaction" class="m-0">
          <b-col cols="9">
            <h5 class="font-weight-bold">หมายเลข: {{ transaction.number }}</h5>

            <h6>
              จำนวนชั่วโมงรวม:
              {{ numberWithCommas(transaction.total_time) }} ชั่วโมง
            </h6>

            <h6>
              จำนวนเงินรวม: {{ numberWithCommas(transaction.total_money) }} บาท
            </h6>
          </b-col>
          <b-col v-if="authUser.isAdminType" cols="3" style="text-align: right;">
            <b-button
              variant="primary"
              @click="syncTeachingDareData"
            >นับยอดล่าสุด</b-button>
          </b-col>
        </b-row>

        <b-alert
          :show="!isReadOnly"
          class="d-flex align-items-center pl-2 ml-2 mt-2 align-content-center"
          variant="info"
        >
          <span class="font-size-lg d-block d-40 mr-2 text-center">
            <font-awesome-icon icon="info-circle" />
          </span>
          <span>
            <strong class="d-block"
              >ระบบจะแสดงแผนการสอนที่บันทึกครบแล้วทั้ง 13 บท เท่านั้น
              เพื่อให้เลือกขอเบิกค่าตอบแทนการสอนได้!</strong
            >
            <!-- <div>
              แผนการสอนที่คุณได้ทำการขอเบิกค่าตอบแทน
              ยังไม่ได้กรอกเวลาการสอนและหลักฐานประกอบการสอนครบ ทั้ง 13 บท
            </div> -->

            <div>
              โดยแผนการสอนนั้น ต้องกรอกวันและเวลาที่สอน และ
              อัพโหลดหลักฐานประกอบการสอนให้ครบถ้วน
            </div>
          </span>
        </b-alert>

        <b-form-group
          v-if="transectionTypes"
          label="ตัวเลือกเบิกค่าตอบแทนการสอน"
          v-slot="{ ariaDescribedby }"
          class="ml-3 mt-3 font-weight-bold ft-s-14"
        >
          <b-form-radio-group
            id="radio-group-transaction-type"
            v-model="selectedTransactionType"
            :options="transectionTypes"
            text-field="description"
            value-field="id"
            :disabled="isReadOnly"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อโรงเรียน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col> -->

      <!-- <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col> -->
      <b-col cols="12" class="mt-2">
        <transaction-alert
          v-for="(record, index) in tableData"
          :key="`alert-validation-${record.id}`"
          :order="index + 1"
          :teaching-dare="record"
        >
        </transaction-alert>
      </b-col>
    </b-row>

    <transection-teaching-dare-table
      ref="table"
      :fields="fields"
      :params="transectionTableParams"
      :isReadOnly="isReadOnly"
      :transaction-type-id="selectedTransactionType"
      @input="onInputChanged"
      @selectTeachingDares="selectTeachingDares"
      @delete="onDeleteTeaching"
      @transactionType="onSelectedType"
    >
    </transection-teaching-dare-table>

    <delete-teaching-dare
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteTeachingData"
    ></delete-teaching-dare>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel">
        {{ isReadOnly ? `ปิด` : `ยกเลิก` }}
      </b-button>

      <b-button
        v-if="!isReadOnly"
        size="sm"
        variant="primary"
        @click="createTransection"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>ยืนยัน</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import TransectionTeachingDareTable from "../../components/table/TransectionTeachingDare";
import { Transection, TransectionType, Auth, TeachingDare } from "../../models";
import FilterMaster from "../../components/form/FilterMaster";
import TransactionAlert from "../alert/transactionAlert";
import DeleteTeachingDare from "./DeleteTeachingDareInTransection.vue";

export default {
  components: {
    TransectionTeachingDareTable,
    FilterMaster,
    TransactionAlert,
    DeleteTeachingDare,
  },

  props: {
    show: Boolean,
    transactionId: Number,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      teachingDares: [],
      isSubmiting: false,
      formfilter: {},
      keyword: "",
      selectedTransactionType: null,
      tableData: [],
      deleteTeachingData: {},
      showModalDelete: false,
    };
  },

  model: {
    prop: "show",
    event: "update",
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    transaction() {
      return Transection.find(this.transactionId);
    },

    transectionTypes() {
      const transectionTypes = TransectionType.query().get();

      return transectionTypes.map((transectionType) => {
        this.$set(
          transectionType,
          "description",
          `${transectionType.m_transaction_name} (ชั่วโมงละ ${transectionType.m_transaction_rate} บาท)`
        );

        return transectionType;
      });
    },

    transectionTableParams() {
      const params = {};
      const { formfilter } = this;

      if (this.isReadOnly) {
        if (this.transactionId) {
          this.$set(formfilter, "transactionId", this.transactionId);
        } else {
          this.$set(formfilter, "transactionId", 0);
        }
      } else {
        // if (!this.authUser.isAdminType) {
        //   this.$set(params, "userProfileId", this.authUser.id);
        // }
      }

      if (!this.transactionId && !this.authUser.isExecutive) {
        this.$set(params, "userProfileId", this.authUser.id);
      }

      return {
        ...formfilter,
        ...params,
      };
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    fields() {
      const additionalFields = [];
      const deleteField = [];

      if (!this.isReadOnly) {
        additionalFields.push({ key: "select", label: "เลือก" });
        additionalFields.push({ key: "userProfile", label: "ชื่อ-นามสกุล" });
      }

      // if (this.authUser.isAdminType) {
      //   additionalFields.push({
      //     key: "user_name",
      //     label: "ชื่อ-นามสกุล",
      //     sortable: true,
      //   });
      // }

      const fields = [
        { key: "station", label: "สถานี", sortable: true },
        { key: "school", label: "โรงเรียน", sortable: true },
        // { key: "amphur", label: "อำเภอ" },
        // { key: "province", label: "จังหวัด" },
        {
          key: "classRoom",
          label: "ห้องเรียน",
          class: "text-center",
          sortable: true,
        },
        {
          key: "yearTerm",
          label: "ปีการศึกษา",
          class: "text-center",
          sortable: true,
        },
        {
          key: "actual_student",
          label: "จำนวนนักเรียน",
          class: "text-center",
          sortable: true,
        },
        { key: "total_time", label: "ชั่วโมงการสอน", class: "text-center" },
        { key: "totalPrice", label: "จำนวนเงิน", class: "text-center" },
        { key: "refresh" },
      ];

      if (this.isReadOnly) {
        deleteField.push({ key: "delete", label: "", requiresSuperAdmin: true });
      }

      return [...additionalFields, ...fields, ...deleteField];
    },
  },

  methods: {
    numberWithCommas(x) {
      return x.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onHidden() {
      this.formfilter = {};

      this.$emit("hide");
    },

    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    selectTeachingDares(v) {
      this.teachingDares = v;
    },

    async syncTeachingDareData() {
      // console.log('====', this.transactionId)
      const { response } = await Transection.api().updateTotal(this.transactionId)
      const { data } = response;
      if (data.response_status == "SUCCESS") {
        this.$toast.success("ดึงข้อมูลรายงานขอเบิกค่าตอบแทนการสอนล่าสุดสำเร็จ");
        // this.$bvModal.hide("create-transection");
        this.$emit("create:success");
      } else {
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลรายงานขอเบิกค่าตอบแทนการสอนล่าสุดได้ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },

    async createTransection() {
      if (this.teachingDares && this.teachingDares.length == 0) {
        return this.$toast.error("กรุณาเลือกชั้นเรียนที่ต้องการเบิกค่าตอบแทน");
      }

      if (!this.selectedTransactionType) {
        return this.$toast.error("กรุณาเลือก 'ตัวเลือกเบิกค่าตอบแทน'");
      }

      let transactionType = TransectionType.find(this.selectedTransactionType);

      this.isSubmiting = true;
      let total_time = 0;
      let total_money = 0;
      let teachingDareIds = [];
      let date = new Date();

      if (this.teachingDares && this.teachingDares.length > 0) {
        for (let index = 0; index < this.teachingDares.length; index++) {
          const teachingDare = this.teachingDares[index];
          teachingDareIds.push({ id: teachingDare.id });
          let {
            response: {
              data: { data },
            },
          } = await TeachingDare.api().findOne(teachingDare.id);

          if (data) {
            // const { teaching_plans, total_time } = data;
            // let tmp_totalTimes = 0;
            // let hours = 0;
            // let mins = 0;

            // if (teaching_plans) {
            //   for (let index = 0; index < teaching_plans.length; index++) {
            //     const element = teaching_plans[index].totalTime;

            //     if (element && element.length) {
            //       const times = element.split(":");

            //       hours += parseInt(times[0]);
            //       mins += parseInt(times[1]);
            //     }
            //   }
            // }
            // tmp_totalTimes = hours + mins / 60;

            // tmp_totalTimes = tmp_totalTimes ? parseInt(tmp_totalTimes) : 0;

            // total_time += tmp_totalTimes;

            total_time += data.total_time;
          }
        }
      }

      total_money = total_time * parseInt(transactionType.m_transaction_rate);

      // for (let index = 0; index < this.teachingDares.length; index++) {
      //   const element = this.teachingDares[index];
      //   total_time += element.time;
      //   total_money += element.totalPrice;
      //   teachingDareIds.push({ id: element.id });
      // }

      try {
        const { response } = await Transection.api().create({
          total_time,
          total_money,
          date,
          teachingDareIds,
          mTransactionTypeId: transactionType.id,
          userProfileId: this.authUser.id,
        });
        const { data } = response;
        if (data && data.response_status == "SUCCESS") {
          this.$toast.success("ทำเรื่องเบิกค่าตอบแทนสำเร็จ");
          this.$bvModal.hide("create-transection");
          this.$emit("create:success", data.data);
        } else {
          this.$toast.error(
            "ไม่สามารถทำเรื่องเบิกค่าตอบแทนได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      } catch (error) {
        console.error('err:', error);
        this.$toast.error(
          "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isSubmiting = false;
      }
    },

    
    onDeleteTeaching(item) {
      this.deleteTeachingData = { ...item };
      this.showModalDelete = true;
    },

    onSelectedType(type) {
      if (type) {
        this.selectedTransactionType = type.id;
      }
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },

    onInputChanged(input) {
      this.tableData = [...input];
    },
  },

  async created() {
    await TransectionType.api().findAll();

    const defaultType = TransectionType.query()
      .where("m_transaction_name", "เบิกกับ บชปส")
      .first();

    if (defaultType) {
      this.selectedTransactionType = defaultType.id;
    }
  },
};
</script>
