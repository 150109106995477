<template>
  <div class="card__flex card card-box mb-3">
    <!-- <div class="card-header pr-2">
      <div class="card-header--title">
        <h4 class="text-danger font-weight-bold">{{ title }}</h4>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div> -->

    <export-modal v-model="isExporting"></export-modal>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
        @input="onTableInputChanged"
      >
        <template #cell(select)="{ item }">
          <div class="text-center">
            <input
              type="checkbox"
              :id="`checkbox-${item.id}`"
              :name="`checkbox-${item.id}`"
              :value="item"
              v-model="selectTeachingDares"
              v-on:change="handleClick"
            />
          </div>
        </template>

        <template #cell(totalPrice)="{ item }">
          {{ getTotalPrice(item) }}
        </template>

        <template #cell(refresh)="{ item, index }">
          <div v-if="isReadOnly" class="text-center">
            <!-- dalete = :disabled="isDisabledTransaction(item)" = for test -->
            <b-button
              size="sm"
              variant="primary"
              :disabled="isDisabledTransaction(item)"
              @click.prevent="printTransaction(item)"
            >
              <b-spinner
                v-if="printingItemId && printingItemId == item.id"
                small
                label="printing..."
              ></b-spinner>
              <span v-else>พิมพ์</span>
            </b-button>

            <generate-pdf
              :id="`generate_pdf_2-${item.id}`"
              :ref="`pdf_2-${item.id}`"
              :item="{
                ...item,
                number: `รายงานเบิกค่าตอนแทนการสอน ${item.transaction.number}-${
                  index + 1
                }.pdf`,
              }"
              :format="format"
              @hasDownloaded="hasDownloaded"
            ></generate-pdf>
          </div>
        </template>

        <template v-slot:cell(delete)="{ item }">
          <div class="text-center">
            <b-button
              size="sm"
              variant="danger"
              :disabled="isLastTeachingDare"
              @click="deleteTeaching(item)"
            >
              ลบ
            </b-button>
          </div>
        </template>

        <!-- <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button variant="danger" size="sm" @click="deleteTeaching(item)"
              >ลบ</b-button
            >
          </div>
        </template> -->
      </base-table>
    </div>
  </div>
</template>

<script>
// import { map } from "lodash";
import formatDateMixin from "../../mixins/formatDateMixin";
import BaseTable from "./Base";
import {
  Auth,
  MRank,
  TeachingDare,
  MPrefix,
  // TeachingPlan,
  TransectionType,
} from "../../models";
import GeneratePdf from "../report/GeneratePdf";
import ExportModal from "../modal/Export";
import { generateTemplate_v2 } from "../../helpers/template-pdf-transaction";

export default {
  mixins: [formatDateMixin],
  props: {
    title: String,
    fields: Array,
    params: Object,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    transactionTypeId: {
      type: [String, Number],
    },
  },

  components: {
    BaseTable,
    GeneratePdf,
    ExportModal,
  },

  data() {
    return {
      isFetching: false,
      isExporting: false,
      currentPage: 1,
      numberOfPages: 1,
      selectTeachingDares: [],
      sortBy: "",
      sortDesc: false,
      format: "",
      printingItemId: null,
      printItem: null,
      isPrefixeAll: {},
      recordData: [],
      recordDataSize: 0,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
    prefixes() {
      return MPrefix.query().get();
    },
    transactionType() {
      return TransectionType.find(this.transactionTypeId);
    },
    isLastTeachingDare() {
      return this.recordDataSize === 1;
    },
  },

  methods: {
    async printTransaction(item) {
      this.isExporting = true;

      this.printingItemId = item.id;

      const promise = await TeachingDare.api().findOne(
        item.id,
        {},
        { save: false }
      );

      if (promise) {
        const { data = {} } = promise.response.data;

        this.printItem = { ...data };

        if (this.prefixes) {
          for (let index = 0; index < this.prefixes.length; index++) {
            const element = this.prefixes[index];
            this.isPrefixeAll[element.id] = { ...element };
          }
        }

        if (this.printItem) {
          let tmpItem = {};

          tmpItem.teaching_dares = [this.printItem];

          this.format = await generateTemplate_v2(tmpItem);

          this.$nextTick(() => {
            if (this.$refs[`pdf_2-${item.id}`]) {
              this.$nextTick(() => {
                const { $refs } = this.$refs[`pdf_2-${item.id}`];

                $refs.html2Pdf.generatePdf();
              });
            }
          });
        } else {
          this.$toast.error("ไม่สามารถพิมพ์รายงานได้ กรุณาลองใหม่อีกครั้ง");
        }
      }
      // สั่ง print รายละเอียด
      // let tmpItem = {};
      // tmpItem.teaching_dares = [item];
      // if (this.$refs && this.$refs[`pdf_2-${item.id}`]) {
      //   const { $refs } = this.$refs[`pdf_2-${item.id}`];
      //   $refs.html2Pdf.generatePdf();
      // }
    },

    hasDownloaded() {
      this.printingItemId = null;
      this.isExporting = false;
    },

    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    handleClick() {
      this.$emit("selectTeachingDares", this.selectTeachingDares);
    },
    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (this.isReadOnly) {
        this.$set(additionalParams, "transactionId", this.params.transactionId);
      } else {
        this.$set(additionalParams, "otherQueries", { isPayment: false });
        this.$set(additionalParams, "isPlan", "ทำแผนการสอน");
      }

      if (this.params && this.params.userProfileId) {
        this.$set(additionalParams, "userProfileId", this.params.userProfileId);
      }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mSchoolId) {
        this.$set(additionalParams, "mSchoolId", this.params.mSchoolId);
      }

      if (this.params && this.params.mClassId) {
        this.$set(additionalParams, "mClassId", this.params.mClassId);
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.room) {
        this.$set(additionalParams, "room", this.params.room);
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", this.sortBy);
        this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      }

      if (!this.authUser.isSuperAdmin) {
        if (!this.authUser.isAdmin) {
          this.$set(additionalParams, "userProfileId", this.authUser.id);
        } else {
          // if (this.authUser.mHeadquarterId) {
          //   this.$set(params, "mHeadquarterId", this.authUser.mHeadquarterId);
          // }
          // if (this.authUser.mDivisionId) {
          //   this.$set(params, "mDivisionId", this.authUser.mDivisionId);
          // }
          // if (this.authUser.mStationId) {
          //   this.$set(params, "mStationId", this.authUser.mStationId);
          // }
        }
      }

      this.isFetching = true;

      try {
        MRank.api().findAll();

        const promise = await TeachingDare.api().findAll(
          {
            ...additionalParams,
            limit: ctx.perPage,
            offset: (ctx.currentPage - 1) * ctx.perPage,
          },
          {
            save: false,
          }
        );

        let { count, data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        // const transactionIds = map(responseData, "id");

        // if (transactionIds && transactionIds.length) {
        //   await TeachingPlan.api().findAll({ transactionIds });
        // }

        this.recordDataSize = count;

        data = responseData.map((record) => {
          let user_name = "";
          let recordId = record.id

          if (record.user_profile) {
            const userRank = MRank.find(record.user_profile.mRankId);

            user_name = `${record.user_profile.first_name} ${record.user_profile.last_name}`;

            if (userRank) {
              user_name = `${userRank.m_rank_name} ${user_name}`;
            }
          }

          let yearTerm = record.fiscal_year || "";

          if (record.term) {
            yearTerm += `/${record.term}`;
          }

          let classRoom = "";

          if (record.m_class) {
            classRoom += record.m_class.m_class_name;

            const roomNumber = parseInt(record.room);

            if (!isNaN(roomNumber)) {
              classRoom += `/${roomNumber}`;
            }
          }

          // const teaching_plans = TeachingPlan.query()
          //   .where("teachingDareId", record.id)
          //   .get();

          // let totalTimes = 0;
          // let hours = 0;
          // let mins = 0;

          // if (teaching_plans) {
          //   for (let index = 0; index < teaching_plans.length; index++) {
          //     const element = teaching_plans[index].totalTime;

          //     if (element && element.length) {
          //       const times = element.split(":");

          //       hours += parseInt(times[0]);
          //       mins += parseInt(times[1]);
          //     }
          //   }
          // }

          // totalTimes = hours + mins / 60;

          // totalTimes = totalTimes ? parseInt(totalTimes) : 0;

          // let price = 0;

          // if (this.transactionType) {
          //   price = this.transactionType.m_transaction_rate;
          // }

          (async () => {
            const promise = await TeachingDare.api().findOne(
              recordId,
              {},
              { save: false }
            );

            if (promise) {
              const { data = {} } = promise.response.data;

              this.recordData.push({ ...data })
            }
          })()

          return {
            ...record,
            order: record.transactionId || "",
            plan_student: record.plan_student || 0,
            actual_student: record.actual_student || 0,
            teaching_plan_count: record.teaching_plan_count || 0,
            room: record.room || "-",
            fiscal_year: record.fiscal_year || "-",
            school: record.m_school ? record.m_school.m_school_name : null,
            station: record.m_station ? record.m_station.m_station_name : null,
            amphur:
              record.m_school && record.m_school.m_amphur
                ? record.m_school.m_amphur.m_amphur_name
                : null,
            province:
              record.m_school && record.m_school.m_province
                ? record.m_school.m_province.m_province_name
                : null,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
            yearTerm,
            classRoom,
            user_name,
            total_time: Math.floor(record.total_time) || 0,
            userProfile: `${record.user_profile.position_name} ${record.user_profile.first_name} ${record.user_profile.last_name}`,
            // time: totalTimes,
            // price,
            // totalPrice: price * totalTimes,
          };
        });

        if (this.isReadOnly) {
          this.$emit("transactionType", data[0].transaction.m_transaction_type);
        }
        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },

    getTotalPrice({ total_time, transaction_rate } = {}) {
      let totalPrice = 0;

      if (this.isReadOnly) {
        totalPrice = transaction_rate * total_time;
      } else {
        if (this.transactionType) {
          totalPrice = this.transactionType.m_transaction_rate * total_time;
        }
      }

      return totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    // getTooltipTitle(item) {
    //   let title = "";

    //   if (item.isPlan === "ไม่ทำแผนการสอน") {
    //     title = "ไม่สามารถพิมพ์รายงานขอเบิกดได้ เนื่องจากยังบันทึกการสอนไม่ครบ";
    //   }

    //   return title;
    // },

    isDisabledTransaction(item) {
      let disabled = true;

      // const teachingPlans = TeachingPlan.query()
      //   .where("teachingDareId", item.id)
      //   .get();

      if (item !== null || item !== undefined) {
        const itemData = this.recordData.find((rec) => rec.id == item.id);

        const isAllTeachingPlansHasTime = item?.teaching_plans?.every(
          (teachingPlan) => teachingPlan.totalTime !== null
        ) || false;

        const isAllTeachingPlansOneHasTime = itemData?.teaching_plans?.every(
          (teachingPlan) => teachingPlan.totalTime !== null
        ) || false;

        // const isAllTeachingPlansHasImage = item.teaching_plans.every(
        //   ({ teaching_actual_imgs = [] } = {}) =>
        //     teaching_actual_imgs.length > 0
        // );

        const isCompleteTime = item.total_time >= 13 || false;

        // disabled = !isAllTeachingPlansHasTime || !isCompleteTime;
        disabled = (!isAllTeachingPlansHasTime && !isAllTeachingPlansOneHasTime) || !isCompleteTime;
      }

      // console.log('----- disabled -----', disabled)
      return disabled;

      // return item.isPlan !== "ทำแผนการสอน";
    },

    onTableInputChanged(input) {
      this.$emit("input", input);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
