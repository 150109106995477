var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"create-transection","centered":"","scrollable":"","size":"xl","no-close-on-backdrop":""},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"dark"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.isReadOnly ? "ปิด" : "ยกเลิก")+" ")]),(!_vm.isReadOnly)?_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.createTransection}},[(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":"","label":"submiting..."}}):_c('span',[_vm._v("ยืนยัน")])],1):_vm._e()]}}]),model:{value:(_vm.$show),callback:function ($$v) {_vm.$show=$$v},expression:"$show"}},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{class:_vm.transaction ? "p-0" : "",attrs:{"cols":"12"}},[(!_vm.isReadOnly)?_c('filter-master',{attrs:{"m-headquarter-id":!_vm.authUser.canAccessAllHeadQuarters ? _vm.authUser.mHeadquarterId : null,"m-division-id":!_vm.authUser.canAccessAllDivisions ? _vm.authUser.mDivisionId : null,"m-station-id":!_vm.authUser.canAccessAllStations ? _vm.authUser.mStationId : null,"disabledInput":{
          headQuarter:
            !_vm.authUser.canAccessAllHeadQuarters &&
            _vm.authUser.mHeadquarterId !== null,
          division:
            !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
          station:
            !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
        },"hiddenInput":{}},model:{value:(_vm.formfilter),callback:function ($$v) {_vm.formfilter=$$v},expression:"formfilter"}}):_vm._e(),(_vm.transaction)?_c('b-row',{staticClass:"m-0"},[_c('b-col',{attrs:{"cols":"9"}},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("หมายเลข: "+_vm._s(_vm.transaction.number))]),_c('h6',[_vm._v(" จำนวนชั่วโมงรวม: "+_vm._s(_vm.numberWithCommas(_vm.transaction.total_time))+" ชั่วโมง ")]),_c('h6',[_vm._v(" จำนวนเงินรวม: "+_vm._s(_vm.numberWithCommas(_vm.transaction.total_money))+" บาท ")])]),(_vm.authUser.isAdminType)?_c('b-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"3"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.syncTeachingDareData}},[_vm._v("นับยอดล่าสุด")])],1):_vm._e()],1):_vm._e(),_c('b-alert',{staticClass:"d-flex align-items-center pl-2 ml-2 mt-2 align-content-center",attrs:{"show":!_vm.isReadOnly,"variant":"info"}},[_c('span',{staticClass:"font-size-lg d-block d-40 mr-2 text-center"},[_c('font-awesome-icon',{attrs:{"icon":"info-circle"}})],1),_c('span',[_c('strong',{staticClass:"d-block"},[_vm._v("ระบบจะแสดงแผนการสอนที่บันทึกครบแล้วทั้ง 13 บท เท่านั้น เพื่อให้เลือกขอเบิกค่าตอบแทนการสอนได้!")]),_c('div',[_vm._v(" โดยแผนการสอนนั้น ต้องกรอกวันและเวลาที่สอน และ อัพโหลดหลักฐานประกอบการสอนให้ครบถ้วน ")])])]),(_vm.transectionTypes)?_c('b-form-group',{staticClass:"ml-3 mt-3 font-weight-bold ft-s-14",attrs:{"label":"ตัวเลือกเบิกค่าตอบแทนการสอน"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-transaction-type","options":_vm.transectionTypes,"text-field":"description","value-field":"id","disabled":_vm.isReadOnly,"aria-describedby":ariaDescribedby},model:{value:(_vm.selectedTransactionType),callback:function ($$v) {_vm.selectedTransactionType=$$v},expression:"selectedTransactionType"}})]}}],null,false,307112993)}):_vm._e()],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},_vm._l((_vm.tableData),function(record,index){return _c('transaction-alert',{key:("alert-validation-" + (record.id)),attrs:{"order":index + 1,"teaching-dare":record}})}),1)],1),_c('transection-teaching-dare-table',{ref:"table",attrs:{"fields":_vm.fields,"params":_vm.transectionTableParams,"isReadOnly":_vm.isReadOnly,"transaction-type-id":_vm.selectedTransactionType},on:{"input":_vm.onInputChanged,"selectTeachingDares":_vm.selectTeachingDares,"delete":_vm.onDeleteTeaching,"transactionType":_vm.onSelectedType}}),_c('delete-teaching-dare',{attrs:{"delete-data":_vm.deleteTeachingData},on:{"delete:success":_vm.onDeleteSuccess,"update:deleteData":function($event){_vm.deleteTeachingData=$event},"update:delete-data":function($event){_vm.deleteTeachingData=$event}},model:{value:(_vm.showModalDelete),callback:function ($$v) {_vm.showModalDelete=$$v},expression:"showModalDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }