<template>
  <div>
    <page-content>
      <page-title heading="รายงานขอเบิกค่าตอบแทนการสอน">
        <template v-slot:body>
          <div class="mt-3 px-3">
            <h4 class="text-danger font-weight-bold">ข้อมูลครูตำรวจ</h4>
            <div>
              <h6 class="font-weight-bold">ชื่อ-นามสกุล : {{ name }}</h6>
              <h6 class="font-weight-bold">ยศ : {{ rank }}</h6>
            </div>
          </div>
        </template>

        <template v-slot:right>
          <b-button
            variant="danger"
            :disabled="authUser.isExecutive"
            @click="onCreate"
            >เพิ่มรายงาน</b-button
          >
        </template>
      </page-title>

      <filter-fiscal-year-term v-model="formFiscalYearTerm" class="mb-2">
      </filter-fiscal-year-term>

      <filter-master
        v-if="authUser.isAdminType"
        v-model="filterMaster"
        :m-headquarter-id="
          !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
        "
        :m-division-id="
          !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
        "
        :m-station-id="
          !authUser.canAccessAllStations ? authUser.mStationId : null
        "
        :disabledInput="{
          headQuarter:
            !authUser.canAccessAllHeadQuarters &&
            authUser.mHeadquarterId !== null,
          division:
            !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
          station:
            !authUser.canAccessAllStations && authUser.mStationId !== null,
        }"
        col-headquarter="3"
        col-division="3"
        col-station="3"
        col-school="3"
        :hidden-input="{ schoolClass: true, room: true }"
      >
      </filter-master>

      <div class="d-flex flex-row flex-wrap mt-3">
        <b-col sm="8" md="6" class="px-2">
          <b-form-group class="mb-0">
            <b-input-group class="input-group-seamless">
              <b-form-input
                placeholder="ค้นหาหมายเลขรายงานขอเบิกค่าตอบแทนการสอน"
                v-model="keyword"
                @keyup.enter="onKeywordSearched"
              ></b-form-input>

              <b-input-group-text slot="prepend">
                <font-awesome-icon class="mx-auto" icon="search" />
              </b-input-group-text>

              <b-input-group-text v-show="keyword.length" slot="append">
                <a href="javascript:void(0)" @click="clearKeyword">
                  <font-awesome-icon class="mx-auto" icon="window-close" />
                </a>
              </b-input-group-text>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="4" md="2" class="px-2 mt-4 mt-sm-0">
          <b-button variant="primary" block size="sm" @click="onKeywordSearched"
            >ค้นหา</b-button
          >
        </b-col>
      </div>

      <guideline-content></guideline-content>

      <div v-if="authUser.isAdminType" class="d-flex flex-column flex-wrap mt-3">
        <b-col sm="12" class="px-2 mb-2" style="font-size: 18px;">รายงานการเบิกสรุปผล</b-col>

        <b-col sm="12" class="px-2 mt-4 mt-sm-0">
          <ul class="mb-0 pl-3">
            <li
              v-for="(item, index) in transactionGroupCount"
              :key="`transaction-count--${item.mTransactionTypeId}-${index}`"
            >
              <b>{{ item.mTransactionTypeName }} :</b> 
              {{ parseInt(item.count).toLocaleString() }} รายการ <b>ยอดรวม :</b> {{ parseInt(item.total_money).toLocaleString() }} บาท
            </li>
          </ul>
        </b-col>
      </div>

      <transection-table
        ref="table_transection"
        class="mt-4"
        :fields="fields"
        :params="params"
        @seeDetail="onSeeDetail"
        @handleCount="setGroupCount"
      >
        <!-- <template v-slot:header-actions>
          <b-button variant="primary" size="sm" @click="onCreate"
            >เพิ่ม</b-button
          >
        </template> -->
      </transection-table>

      <create-transection
        v-model="showModalTransection"
        :transactionId="transactionId"
        :isReadOnly="isReadOnly"
        @create:success="onCreateSuccess"
        @hide="clearData"
      ></create-transection>

      <!-- <generate-pdf
        id="gen-pdf-create-transaction"
        key="gen-pdf-create-transaction"
        ref="gen_pdf"
        :item="item"
        :format="format"
      ></generate-pdf> -->
    </page-content>
  </div>
</template>

<script>
import CreateTransection from "../../components/modal/CreateTransection";
import TransectionTable from "../../components/table/Transection";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import FilterMaster from "../../components/form/FilterMaster";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import GuidelineContent from "../../components/layout/GuidelineContent";
import { Auth, MRank } from "../../models";
// import { generateTemplate } from "../../helpers/template-pdf-transaction";
// import GeneratePdf from "../../components/report/GeneratePdf";
const defaultValue = "ทั้งหมด";

export default {
  data() {
    return {
      name: "",
      rank: "",
      keyword: "",
      filterMaster: {},
      formfilter: {},
      showModalTransection: false,
      transactionId: null,
      isReadOnly: false,
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },
      transactionGroupCount: [],
      // format: "",
      // item: {},
    };
  },

  components: {
    PageContent,
    PageTitle,
    TransectionTable,
    CreateTransection,
    FilterMaster,
    FilterFiscalYearTerm,
    GuidelineContent,
    // GeneratePdf,
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const fields = [
        // { key: "order", label: "ลำดับ" },
        // { key: "station", label: "สถานี" },
        // { key: "school", label: "โรงเรียน" },
        // { key: "amphur", label: "อำเภอ" },
        // { key: "province", label: "จังหวัด" },
        // { key: "room", label: "ห้องเรียน", class: "text-center" },
        // { key: "fiscal_year", label: "ปีการศึกษา", class: "text-center" },
        // { key: "actual_student", label: "จำนวนนักเรียน", class: "text-center" },
        {
          key: "number",
          label: "หมายเลข",
          class: "text-center",
          sortable: true,
        },
        { key: "user_name", label: "ยศ ชื่อ-สกุล" },
        {
          key: "total_time",
          label: "ชั่วโมงการสอน",
          class: "text-center",
          sortable: true,
        },
        {
          key: "total_money",
          label: "จำนวนเงิน",
          class: "text-center",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
      ];

      return fields;
    },

    params() {
      const { filterMaster: params, formfilter } = this;

      return { ...params, ...formfilter, ...this.getFilterTermYear() };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    onCreate() {
      this.showModalTransection = true;
    },

    async init() {
      try {
        const authUser = Auth.user();

        this.name = `${authUser.first_name} ${authUser.last_name}`;

        await MRank.api().findAll();

        this.rank = MRank.find(authUser.mRankId)
          ? MRank.find(authUser.mRankId).m_rank_name
          : "";
      } catch (error) {
        console.error(error);
      }
    },

    onCreateSuccess() {
      this.$refs.table_transection.refresh();
      // this.item = { ...v };
      // this.format = generateTemplate(this.authUser, this.item);
      // if (this.$refs.gen_pdf) {
      //   this.$nextTick(() => {
      //     const { $refs } = this.$refs.gen_pdf;
      //     $refs.html2Pdf.generatePdf();
      //   });
      // }
    },

    setGroupCount(group) {
      this.transactionGroupCount = group
    },

    onSeeDetail(item) {
      this.transactionId = item.id;
      this.isReadOnly = true;
      this.showModalTransection = true;
    },
    clearData() {
      this.transactionId = null;
      this.isReadOnly = false;
    },
  },

  mounted() {
    this.init();
  },
};
</script>
