<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <h4 class="text-danger font-weight-bold">{{ title }}</h4>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <template #cell(actions)="{ item }">
          <div class="text-right">
            <b-button size="sm" @click="seeDetail(item)" class="mr-2"
              >ดูรายละเอียด</b-button
            >
          </div>
        </template>

        <template
          v-for="valueField in valueFields"
          v-slot:[`cell(${valueField})`]="{ item }"
        >
          <div :key="`template-${valueField}`">
            {{
              item[`${valueField}`]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}
          </div>
        </template>

        <!-- <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button
              size="sm"
              variant="primary"
              @click="printTransaction(item)"
            >
              <b-spinner
                v-if="printingItemId && printingItemId == item.id"
                label="printing..."
              ></b-spinner>
              <span v-else>พิมพ์</span>
            </b-button>
            <generate-pdf
              ref="pdf"
              :item="item"
              :format="format"
              @onProgressDone="onProgressDone"
            ></generate-pdf>
          </div>
        </template> -->
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import BaseTable from "./Base";
import { Auth, MRank, Transection, MPrefix } from "../../models";
// import GeneratePdf from "../report/GeneratePdf";
// import { generateTemplate } from "../../helpers/template-pdf-transaction";
// import SelectTypeCreateTransaction from "../modal/SelectTypeCreateTransaction";

export default {
  mixins: [formatDateMixin],
  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "ดูรายละเอียด/พิมพ์",
    },
  },

  components: {
    BaseTable,
    // GeneratePdf,
    // SelectTypeCreateTransaction,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      format: "",
      printingItemId: null,
      isPrefixeAll: {},
      sortBy: "",
      sortDesc: false,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
    valueFields() {
      return ["total_time", "total_money"];
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1,
        group_count = [];

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      // if (!this.authUser.isAdminType) {
      //   this.$set(additionalParams, "userProfileId", this.authUser.id);
      // }

      // if (!this.authUser.isExecutive && !this.authUser.isAdminType) {
      //   this.$set(additionalParams, "userProfileId", this.authUser.id);
      // }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(additionalParams, "mHeadquarterId", this.params.mHeadquarterId);
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.mSchoolId) {
        this.$set(additionalParams, "mSchoolId", this.params.mSchoolId);
      }

      if (this.params && this.params.fiscalYearFrom) {
        this.$set(additionalParams, "fiscalYearFrom", this.params.fiscalYearFrom);
      }

      if (this.params && this.params.termFrom) {
        this.$set(additionalParams, "termFrom", this.params.termFrom);
      }

      if (this.params && this.params.fiscalYearTo) {
        this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
      }

      if (this.params && this.params.termTo) {
        this.$set(additionalParams, "termTo", this.params.termTo);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", this.sortBy);
        this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      }

      this.isFetching = true;

      try {
        const params = {};

        if (!ctx.downloadAll) {
          this.$set(params, "limit", ctx.perPage);
          this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
        }

        await MRank.api().findAll();
        await MPrefix.api().findAll();

        const prefixs = MPrefix.query().get();

        for (let index = 0; index < prefixs.length; index++) {
          const element = prefixs[index];
          this.isPrefixeAll[element.id] = { ...element };
        }

        const promise = await Transection.api().findAll(
          {
            ...params,
            ...additionalParams,
          },
          { save: false }
        );

        let {
          data: responseData = [],
          number_of_pages = 1,
          groupCount,
        } = promise.response.data;

        data = responseData
          // .filter((rec) => rec.teaching_dares.length > 0)
          .map((record) => {
          let user_name = "",
            user_id_governmen = "";

          let yearTerm = record.fiscal_year || "";

          if (record.term) {
            yearTerm += `/${record.term}`;
          }

          let classRoom = "";

          if (record.m_class) {
            classRoom += record.m_class.m_class_name;

            const roomNumber = parseInt(record.room);

            if (!isNaN(roomNumber)) {
              classRoom += `/${roomNumber}`;
            }
          }

          if (record.teaching_dares) {
            const firstTeachingDare = record.teaching_dares[0];

            if (firstTeachingDare && firstTeachingDare.user_profile) {
              const firstUserProfile = firstTeachingDare.user_profile;

              const userRank = MRank.find(firstUserProfile.mRankId);

              user_name = `${firstUserProfile.first_name} ${firstUserProfile.last_name}`;
              user_id_governmen = firstUserProfile.id_governmen;

              if (userRank) {
                user_name = `${userRank.m_rank_name} ${user_name}`;
              }
            }
          }

          return {
            ...record,
            // plan_student: record.plan_student || 0,
            // actual_student: record.actual_student || 0,
            // teaching_plan_count: record.teaching_plan_count || 0,
            // room: record.room || "-",
            // fiscal_year: record.fiscal_year || "-",
            // school: record.m_school ? record.m_school.m_school_name : null,
            // station: record.m_station
            //   ? record.m_station.m_station_name
            //   : null,
            // amphur: record.m_school.m_amphur
            //   ? record.m_school.m_amphur.m_amphur_name
            //   : null,
            // province: record.m_school.m_province
            //   ? record.m_school.m_province.m_province_name
            //   : null,
            updatedAt: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            createdAt: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
            yearTerm,
            classRoom,
            user_name,
            user_id_governmen,
            total_time: record.total_time ? parseInt(record.total_time) : 0,
            total_money: record.total_money ? parseInt(record.total_money) : 0,
          };
        });

        numberOfPages = number_of_pages;
        group_count = groupCount
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
        // this.groupCount = group_count;
        this.$emit("handleCount", group_count);
      }

      return data;
    },

    seeDetail(item) {
      // เปิด modal ดูรายละเอียด
      this.$emit("seeDetail", item);
    },

    // onCreateTransaction(item) {
    //   this.$bvModal.show(`select-type-create-transaction-modal_${item.id}`);
    // },

    // async printTransaction(item) {
    //   // สั่ง print รายละเอียด
    //   this.printingItemId = item.id;
    //   this.format = await generateTemplate(item);

    //   if (this.$refs.pdf) {
    //     const { $refs } = this.$refs.pdf;

    //     $refs.html2Pdf.generatePdf();
    //   }
    // },

    onProgressDone() {
      this.printingItemId = null;
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
