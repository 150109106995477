<template>
  <b-alert
    :show="someDataIncompeleted"
    class="d-flex align-items-center pl-2 align-content-center"
    variant="warning"
  >
    <span class="font-size-lg d-block d-40 mr-2 text-center">
      <font-awesome-icon icon="question-circle" />
    </span>
    <span>
      <strong class="d-block"
        >คุณยังบันทึกแผนการสอนลำดับที่ {{ order }} ไม่ครบ!</strong
      >
      <!-- <div>
              แผนการสอนที่คุณได้ทำการขอเบิกค่าตอบแทน
              ยังไม่ได้กรอกเวลาการสอนและหลักฐานประกอบการสอนครบ ทั้ง 13 บท
            </div> -->

      <!-- <div>
              กรุณาบันทึกการสอนให้ครบ ทั้ง 13 บท โดยกรอกวันและเวลาที่สอน และ
              อัพโหลดหลักฐานประกอบการสอนให้ครบ
            </div>

            <div>
              ไม่เช่นนั้นคุณจะไม่สามารถพิมพ์รายงานขอเบิกค่าตอนแทน
              ของแผนการสอนนั้นๆได้
            </div> -->
      <ul
        v-for="(message, index) in alertMessages"
        :key="`alert-message--${teachingDare.id}-${index}`"
        class="mb-0"
      >
        <li>{{ message }}</li>
      </ul>
    </span>
  </b-alert>
</template>

<script>
import { TeachingPlan, TeachingDare } from "../../models";

export default {
  props: {
    teachingDare: {
      type: Object,
      required: true,
    },

    order: {
      type: [Number, String],
    },
  },

  data() {
    return {
      teachingDareData: {},
    }
  },

  computed: {
    teachingPlans() {
      return TeachingPlan.query()
        .where("teachingDareId", this.teachingDare.id)
        .get();
    },

    // teachingDareData: function async () {
    //   return TeachingDare
    //     .api()
    //     .findOne(this.teachingDare.id);
    // },

    someDataIncompeleted() {
      // return this.tableData.some((record) => {
      //   return record.isPlan !== "ทำแผนการสอน";
      // });
      return (
        !this.isAllTeachingPlansHasTime 
        // || !this.isAllTeachingPlansHasImage
      );
    },

    isAllTeachingPlansHasTime() {
      let res = false;

      // const { teachingPlans = [] } = this;

      // if (teachingPlans && teachingPlans.length) {
      //   res = teachingPlans.every(
      //     (teachingPlan) => teachingPlan.totalTime !== null
      //   );
      // }

      const { teachingDareData } = this;

      res = teachingDareData.teaching_plans.every(
        (teachingPlan) => teachingPlan.totalTime !== null
      );

      return res;
    },

    isAllTeachingPlansHasImage() {
      let res = false;

      const { teachingPlans = [] } = this;

      if (teachingPlans && teachingPlans.length) {
        res = teachingPlans.every(
          ({ teaching_actual_imgs = [] } = {}) =>
            teaching_actual_imgs.length > 0
        );
      }

      return res;
    },

    alertMessages() {
      let messages = [];

      const { teachingDareData } = this;

      const noTimeLessons = [];

      teachingDareData.teaching_plans.forEach((teachingPlan) => {
        const {
          totalTime = null,
          // teaching_actual_imgs = []
        } = teachingPlan;

        if (totalTime === null) {
          noTimeLessons.push(teachingPlan.lesson_number);
        }

        // if (!teaching_actual_imgs.length) {
        //   noImageLessons.push(teachingPlan.lesson_number);
        // }
      });

      if (noTimeLessons.length) {
        messages.push(
          `วันและเวลาที่สอน ในบทที่ ${noTimeLessons.join(", ")} ยังไม่ได้บันทึก`
        );
      }

      // if (noImageLessons.length) {
      //   messages.push(
      //     `หลักฐานประกอบการสอน ในบทที่ ${noImageLessons.join(
      //       ", "
      //     )} ยังไม่ได้บันทึก`
      //   );
      // }

      return messages;
    },
  },

  methods: {
    async fetchTeachingDare() {
      let promise = await TeachingDare.api().findOne(this.teachingDare.id);

      if (promise) {
        const { data } = promise.response.data;

        this.teachingDareData = data;
      }
    }
  },

  created() {
    this.fetchTeachingDare();
  }
};
</script>
